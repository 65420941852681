import { useState, useEffect } from 'react';

//Mui imports
import {
    Button,
    Grid,
    Typography,
    Card,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Select,
    Stack,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Divider,
    FormHelperText,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    AccordionActions,
    CircularProgress,
    Link
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import BagType from 'ui-component/BagType';

//--------------------------------Waste Collection Rerpot Table------------------------------------//

const ReportTableList = (props) => {
    const { reportData } = props;
    const theme = useTheme();

    return (
        <>
            {reportData != undefined || reportData != null ? (
                <Card variant="outlined" sx={{ marginTop: 2 }}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                <TableRow>
                                    <TableCell sx={{ width: 60 }}>Sl.</TableCell>
                                    <TableCell sx={{ width: 150 }} align="left">
                                        Barcode
                                    </TableCell>
                                    <TableCell sx={{ width: 80 }} align="left">
                                        Collection Date-Time
                                    </TableCell>
                                    <TableCell sx={{ width: 80 }} align="left">
                                        Bag Type
                                    </TableCell>

                                    <TableCell sx={{ width: 150 }} align="left">
                                        Collected By
                                    </TableCell>
                                    <TableCell sx={{ width: 150 }} align="left">
                                        Weight (Kg.)
                                    </TableCell>
                                    <TableCell sx={{ width: 150 }} align="left">
                                        Collection Type
                                    </TableCell>
                                    <TableCell sx={{ width: 150 }} align="left">
                                        Status
                                    </TableCell>
                                    <TableCell sx={{ width: 150 }} align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportData.map((data, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                <Typography variant="body2">{index + 1}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{data.barcode}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{new Date(data.date).toLocaleString()}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <BagType bagType={data.bagType} />
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{data.driverName}</Typography>
                                                <Typography variant="caption">{data.vehicleNumber}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{data.weight}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{data.collectionTypeString}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{data.statusString}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Link
                                                    href={`https://www.google.com/maps/search/?api=1&query=${data.latitude}%2C${data.longitude}`}
                                                    target={'_blank'}
                                                >
                                                    <LocationOnIcon />
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            ) : null}
        </>
    );
};

export default ReportTableList;
