import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeMenu: 0,
    drawerOpened: true
};

export const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setMenuDrawer: (state, action) => {
            state.drawerOpened == action.payload;
            console.log(action.payload);
        },
        setActiveMenu: (state, action) => {
            state.activeMenu = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setMenuDrawer, setActiveMenu } = layoutSlice.actions;

export default layoutSlice.reducer;
