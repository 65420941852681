import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userId: 0,
    profileId: 0,
    displayName: '',
    role: ''
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.userId = action.payload.userId;
            state.profileId = action.payload.profileId;
            state.displayName = action.payload.displayName;
            state.role = action.payload.role;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions;

export default userSlice.reducer;
