import { useSelector } from 'react-redux';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import adminMenuItems from 'menu-items/adminMenuItems';
import customerMenuItems from 'menu-items/customerMenuItems';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { role } = useSelector((state) => state.userReducer);

    let menuItemsTobAdded = [];
    if (role == 'Customer') {
        menuItemsTobAdded = customerMenuItems;
    } else {
        menuItemsTobAdded = adminMenuItems;
    }

    const navItems = menuItemsTobAdded.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
