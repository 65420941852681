// material-ui
import { Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';

import logo from 'assets/images/logo.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();
    return <img src={logo} alt="Surya" width="95" />;
};

export default Logo;
