import React from 'react';
import { useTheme, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const AlertMessage = ({ message, opendeleteTost, handleCloseDeleteToast }) => {
    const theme = useTheme();
    return (
        <>
            <Snackbar open={opendeleteTost} autoHideDuration={6000} onClose={handleCloseDeleteToast}>
                <Alert
                    onClose={handleCloseDeleteToast}
                    severity="success"
                    sx={{ width: '100%', backgroundColor: theme.palette.success.dark }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default AlertMessage;
