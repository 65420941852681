import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';
//redux import
import { useSelector } from 'react-redux';
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const user = useSelector((state) => state.userReducer);
    console.log('user', user);

    return useRoutes([MainRoutes, AuthenticationRoutes], config.basename);
}
