import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';

const BagType = (props) => {
    const { bagType } = props;
    const size = 24;
    return (
        <Grid sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            {(() => {
                switch (bagType) {
                    case 0:
                        return (
                            <Box
                                sx={{
                                    backgroundColor: 'yellow',
                                    height: size,
                                    width: size,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'solid',
                                    borderColor: 'black',
                                    borderWidth: 0.5,
                                    borderRadius: 2
                                }}
                            ></Box>
                        );
                    case 1:
                        return (
                            <Box
                                sx={{
                                    backgroundColor: 'red',
                                    height: size,
                                    width: size,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'solid',
                                    borderColor: 'black',
                                    borderWidth: 0.5,
                                    borderRadius: 2
                                }}
                            ></Box>
                        );
                    case 2:
                        return (
                            <Box
                                sx={{
                                    backgroundColor: 'white',
                                    height: size,
                                    width: size,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'solid',
                                    borderColor: 'black',
                                    borderWidth: 0.5,
                                    borderRadius: 2
                                }}
                            ></Box>
                        );
                    case 3:
                        return (
                            <Box
                                sx={{
                                    backgroundColor: 'blue',
                                    height: size,
                                    width: size,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'solid',
                                    borderColor: 'black',
                                    borderWidth: 0.5,
                                    borderRadius: 2
                                }}
                            ></Box>
                        );
                    case 4:
                        return (
                            <Box
                                sx={{
                                    backgroundColor: 'yellow',
                                    height: size,
                                    width: size,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: 'solid',
                                    borderColor: 'black',
                                    borderWidth: 0.5,
                                    borderRadius: 2
                                }}
                            >
                                <Typography>C</Typography>
                            </Box>
                        );
                    default:
                        return null;
                }
            })()}
        </Grid>
    );
};

export default BagType;
