// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const borderRadius = 3;

export const bagSaleTypes = [
    'Yellow (30x36)',
    'Yellow (26x28)',
    'Yellow (21x26)',
    'Yellow (19x20)',
    'Red (30x36)',
    'Red (26x28)',
    'Red (21x26)',
    'Red (19x20)',
    'Black (30x36)',
    'Black (21x26)'
];
